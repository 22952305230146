import _ from 'lodash'
import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react'
// import { Animated } from 'react-native'
import { useAuth, useUser } from 'react-omnitech-api'
import { useTranslation } from 'react-i18next'
import ProductAddonsView from './product-addons-view'
import {
  isNullOrUndefined,
  parseStockLevel,
  // isSkuAllowToCheckout,
  getCharacterCounts,
} from '../../../../helpers'
import {
  useCustomerRankSettings,
} from '../../../../hook'
// import Locale from '../../../services/locale'

const ProductAddonsController = (props) => {
  const {
    productAddons = [],
    siblings = [],
    value = [],
    touched = [],
    onChange = () => {},
    onUpdate,
    onValidate = () => { },
    getAddonAvailableQuantity = () => (Infinity),
    displayErrors,
    isEdit,
    isReadOnly,
    ...otherProps
  } = props

  // hooks
  const { t, i18n } = useTranslation()
  const { auth } = useAuth()
  const { loyaltyInformation } = useUser()
  const { default: defaultRank } = useCustomerRankSettings()

  const currentLocale = i18n.language
  const customerRankCode = _.get(loyaltyInformation, 'currentCustomerRank.code', '')
  // orderMethod: _.get(state, 'orderMethod.orderMethod', {}),
  const isLoggedIn = !_.isEmpty(_.toString(_.get(auth, 'userId', '')))

  const siblingSkus = _.flatMap(siblings, 'skus')

  const addonSkuPrice = useCallback(({ sku, display = 'increment' }) => {
    const rankCode = _.camelCase(isLoggedIn ? customerRankCode : defaultRank)
    const sibling = _.find(siblingSkus, { id: sku.id })
    const sellPrice = _.get(sibling, 'sellPrice') || _.get(sku, 'sellPrice')
    const memberPrice = _.get(sibling, `meta.memberPrice.${rankCode}`) || _.get(sku, `meta.memberPrice.${rankCode}`)
    if (!sellPrice || sellPrice === '0.0' || display === 'hidden') return false
    return [
      {
        sellPrice,
        prefix: display !== 'fixed' && '+',
        ...memberPrice && {
          label: t('screens.product.price.nonMember'),
        },
      },
      memberPrice && {
        label: t('screens.product.price.member'),
        sellPrice: memberPrice,
        prefix: display !== 'fixed' && '+',
      },
    ]
  }, [customerRankCode, defaultRank, siblingSkus, isLoggedIn, customerRankCode, t])

  const selectionType = ({ minimumSkuSelect, maximumPerSkuQuantity, maximumSkuSelect }) => {
    switch (true) {
      case (maximumPerSkuQuantity > 1):
        return 'quantity'
      case (maximumSkuSelect > 1 || minimumSkuSelect < 1):
        return 'multiple'
      default:
        return 'single'
    }
  }

  // check if customer remarks is valid
  const isCustomerRemarkValid = (customerRemark, skuCode, meta) => {
    const addonCustomerRemarkSettings = _.find(
      _.get(meta, 'addonCustomerRemarks', []),
      { code: skuCode },
    )
    if (!_.isEmpty(addonCustomerRemarkSettings)) {
      const {
        maxNoOfLines,
        maxNonEnglishCharacters,
        maxCharacters,
        minCharacters = 0,
        englishCharacterLength = 1,
        nonEnglishCharacterLength = 2,
      } = addonCustomerRemarkSettings
      const charCounts = getCharacterCounts(
        customerRemark,
        { asciiCharLength: englishCharacterLength, nonAsciiCharLength: nonEnglishCharacterLength },
      )
      if (charCounts.numberOfLines > maxNoOfLines - 1
        || charCounts.numberOfNonAsciiChars > maxNonEnglishCharacters
        || charCounts.numberOfChars > maxCharacters
        || charCounts.numberOfChars < minCharacters
      ) {
        return false
      }
    }
    return true
  }

  const sections = useMemo(() => {
    const updatedSections = _.reduce(productAddons, (result, _addon, _addonIndex) => {
      const createSection = (addon, parents = []) => {
        const {
          id,
          addons,
          meta,
          minimumPerSkuQuantity,
          minimumSkuSelect,
          minimumTotalQuantity,
          maximumPerSkuQuantity,
          maximumSkuSelect,
          parentProductAddonId,
        } = addon

        const quantityIndex = _.get(_.last(parents), 'quantityIndex', 0)

        if (_.get(meta, 'sectionHeader', false)) {
          // backward compatible
          const headerProduct = _.first(addons)
          const nextAddon = _.nth(productAddons, _addonIndex + 1)
          result.push({
            // ...addon,
            title: _.get(headerProduct, 'title'),
            description: _.get(headerProduct, 'detailsPlainText'),
            required: _.sum([
              _.get(nextAddon, 'minimumSkuSelect', 0),
              _.get(nextAddon, 'minimumPerSkuQuantity', 0),
              _.get(nextAddon, 'minimumTotalQuantity', 0),
            ]) > 0,
          })
          return result
        }

        const type = selectionType({ minimumSkuSelect, maximumPerSkuQuantity, maximumSkuSelect })
        const commonOptionsProps = {
          productAddonId: id,
          quantityIndex,
        }
        const findValue = ({
          addonId: _addonId,
          productAddonId: _productAddonId,
          quantityIndex: _quantityIndex,
          skuId: _skuId,
        }) => _.filter(value, (item) => (
          _.isEqual(_.get(item, 'addonId'), _addonId)
            && _.isEqual(_.get(item, 'productAddonId'), _productAddonId)
            && _.isEqual(_.get(item, 'quantityIndex'), _quantityIndex)
            && _.isEqual(_.get(item, 'skuId'), _skuId)
            && (
              _.isEqual(_.get(_.last(_.get(item, 'parents')), 'productAddonId'), parentProductAddonId)
              || (
                isNullOrUndefined(_.get(_.last(_.get(item, 'parents')), 'productAddonId'))
                && isNullOrUndefined(parentProductAddonId)
              )
            )
        ))
        const options = _.flatMap(
          addons,
          ({
            id: addonId,
            skus,
            colorOptions,
            meta: addonMeta,
            // title: groupTitle,
          }) => (
            _.map(skus, (sku) => {
              const {
                id: skuId,
                code: skuCode,
                colorOptionId,
                stockLevel,
              } = sku
              const stockLevelInNumber = parseStockLevel(stockLevel)
              const outOfStock = stockLevelInNumber <= 0
              let addonName = _.get(_.find(colorOptions, { id: colorOptionId }), 'name')
              const optionValues = findValue({
                addonId,
                productAddonId: id,
                quantityIndex,
                skuId,
              })
              const quantity = _.sumBy(
                optionValues,
                'quantity',
              )
              const isOptionSelected = quantity > 0
              const impossibleToFulfill = isOptionSelected
                && minimumPerSkuQuantity > stockLevelInNumber
              const maxQty = _.min([
                maximumPerSkuQuantity,
                stockLevelInNumber,
                getAddonAvailableQuantity(sku),
              ])
              // [TODO: checkSkuForCheckout()]
              const disabled = outOfStock || impossibleToFulfill || maxQty < 1
              if (outOfStock) {
                addonName += ` (${t('screens.product.addons.item.outOfStock')})`
              } else if (disabled) {
                addonName += ` (${t('screens.product.addons.item.unavailable')})`
              }
              return ({
                id: skuId,
                skuCode,
                title: addonName,
                addonId,
                quantity,
                disabled,
                price: addonSkuPrice({ sku, display: _.get(addonMeta, 'price', _.isNull(id) ? 'fixed' : 'increment') }),
                max: maxQty,
                stockLevel: stockLevelInNumber,
                customerRemark: _.get(optionValues, '0.customerRemark', ''),
                ...commonOptionsProps,
              })
            })
          ),
        )

        const required = _.sum([minimumSkuSelect, minimumPerSkuQuantity, minimumTotalQuantity]) > 0
        const error = displayErrors && (
          _.sumBy(options, 'quantity') < minimumTotalQuantity
                        || _.some(options, ({ quantity }) => quantity < minimumPerSkuQuantity)
                        || _.size(
                          _.filter(options, ({ quantity }) => quantity > 0),
                        ) < minimumSkuSelect
        )

        result.push({
          ...addon,
          title: _.get(meta, `addonHeader.title.${_.camelCase(currentLocale)}`),
          description: _.get(meta, `addonHeader.description.${_.camelCase(currentLocale)}`),
          required,
          error,
          type,
          options,
          parents,
        })
        // if selected and have nested addons
        _.each(options, ({
          quantity,
          addonId,
          id: selectedSkuId,
          productAddonId,
        }) => {
          const selectedAddon = _.find(addons, { id: addonId })
          _.times(quantity, (qtyIdx) => {
            _.each(
              _.get(selectedAddon, 'productAddons', []),
              (pa) => {
                const nextParents = _.compact([
                  ...parents,
                  {
                    skuId: selectedSkuId,
                    addonId,
                    quantityIndex: qtyIdx,
                    productAddonId: id,
                  },
                ])
                createSection({ ...pa, parentProductAddonId: productAddonId }, nextParents)
              },
            )
          })
        })
      }
      createSection(_addon)
      return result
    }, [])

    const requiredSections = _.filter(
      updatedSections,
      ({
        minimumSkuSelect = 0,
        minimumPerSkuQuantity = 0,
        minimumTotalQuantity = 0,
      }) => (minimumSkuSelect + minimumPerSkuQuantity + minimumTotalQuantity) > 0,
    )
    const isAllRequriedAddonReady = _.isUndefined(
      _.find(requiredSections, ({
        minimumSkuSelect = 0,
        minimumPerSkuQuantity = 0,
        minimumTotalQuantity = 0,
        id,
        meta,
      }) => {
        const sectionValues = _.filter(value, { productAddonId: id })
        const selectedSectionValues = _.filter(sectionValues, ({ quantity }) => quantity > 0)
        // check customer remarks
        if (!isCustomerRemarkValid(
          _.get(selectedSectionValues, '0.customerRemark'),
          _.get(selectedSectionValues, '0.skuCode'),
          meta,
        )) {
          return true
        }

        return _.sumBy(sectionValues, 'quantity') < minimumTotalQuantity
            || _.some(sectionValues, ({ quantity }) => quantity < minimumPerSkuQuantity)
            || _.size(_.filter(sectionValues, ({ quantity }) => quantity > 0)) < minimumSkuSelect
      }),
    )
    onValidate(isAllRequriedAddonReady)
    return updatedSections
  }, [
    productAddons,
    onValidate,
    displayErrors,
    currentLocale,
    value,
    addonSkuPrice,
    getAddonAvailableQuantity,
  ])

  // useImperativeHandle(ref, () => ({
  //   reset: () => {
  //     // setChanges([])
  //   }
  // }))

  const onSectionUpdate = (val) => {
    const {
      customerRemark: updatedCustomerRemark,
      value: selectedOptionId,
      meta,
      options,
    } = val
    const skuCode = _.get(_.find(options, { id: selectedOptionId }), 'skuCode')
    const addonCustomerRemarkSettings = _.find(
      _.get(meta, 'addonCustomerRemarks', []),
      { code: skuCode },
    )
    if (!_.isEmpty(addonCustomerRemarkSettings) && _.isFunction(onUpdate)) {
      const isValidCustomerRemark = isCustomerRemarkValid(updatedCustomerRemark, skuCode, meta)
      onUpdate({
        ...val,
        isValidCustomerRemark,
      })
    }
  }

  const onSectionChange = useCallback((val) => {
    const {
      id: productAddonId,
      options = [],
      parents = [],
      type,
      value: updatedValue,
      customerRemark: updatedCustomerRemark,
    } = val

    const quantityIndex = _.get(_.last(parents), 'quantityIndex', 0)
    const otherSectionsValue = _.reject(
      value,
      ({
        productAddonId: origProductAddonId,
        quantityIndex: origQuantityIndex,
        parents: origParents,
      }) => (
        _.isEqual(origProductAddonId, productAddonId)
        && _.isEqual(origQuantityIndex, quantityIndex)
        && (
          _.isEqual(_.get(_.last(origParents), 'productAddonId'), _.get(_.last(parents), 'productAddonId'))
            || (
              isNullOrUndefined(_.get(_.last(origParents), 'productAddonId'))
              && isNullOrUndefined(_.get(_.last(parents), 'productAddonId'))
            )
        )
      ),
    )

    const newValue = _.flatMap(_.castArray(updatedValue), (updatedValueItem) => {
      if (type === 'quantity') {
        return _.map(updatedValueItem, (quantity, selectedQtyOptionId) => {
          const selectedQtyOptionIdInNumber = _.toNumber(selectedQtyOptionId)
          const selectedQtyOption = _.find(options, { id: selectedQtyOptionIdInNumber })
          return {
            ..._.pick(selectedQtyOption, [
              'addonId',
              'productAddonId',
              'skuCode',
              'title',
            ]),
            quantity,
            quantityIndex,
            skuId: selectedQtyOptionIdInNumber,
            stockLevel: _.get(selectedQtyOption, 'stockLevel'),
            parents,
            customerRemark: updatedCustomerRemark || undefined,
          }
        })
        // return _.map(options, (option))
      }
      const selectedOption = _.find(options, { id: updatedValueItem })
      return {
        ..._.pick(selectedOption, [
          'addonId',
          'productAddonId',
          'skuCode',
          'title',
        ]),
        quantity: 1,
        quantityIndex,
        skuId: updatedValueItem,
        stockLevel: _.get(selectedOption, 'stockLevel'),
        parents,
        customerRemark: updatedCustomerRemark || undefined,
      }
    })

    const removedOptions = _.reduce(
      options,
      (result, option) => {
        const {
          id: optionSkuId,
          quantity,
        } = option
        const newValueQty = _.get(_.find(newValue, { skuId: optionSkuId }), 'quantity', 0)
        if (newValueQty < quantity) {
          const subtractedQty = quantity - newValueQty
          result.push({
            ...option,
            quantity: subtractedQty,
            quantityIndex: quantity - subtractedQty,
          })
        }
        return result
      },
      [],
    )
    const removedChildrenValue = _.filter(
      otherSectionsValue,
      ({ parents: origParents = [], quantityIndex: origQuantityIndex }) => {
        const optionsHaveMatchedParent = _.filter(
          removedOptions,
          ({ id: skuId, addonId, productAddonId: removedOptionProductAddonId }) => (
            _.some(origParents, { skuId, addonId, productAddonId: removedOptionProductAddonId })
          ),
        )
        return !_.some(optionsHaveMatchedParent, { quantityIndex: origQuantityIndex })
      },
    )
    const mergedValue = [
      ...removedChildrenValue,
      ...newValue,
    ]

    // delete productAddonId from touched list if options have removed
    const origTouched = _.difference(touched, _.map(removedOptions, 'productAddonId'))
    // combine current productAddonId to touched list
    const updatedTouched = _.uniq([
      ...origTouched,
      productAddonId,
    ])

    onChange(
      mergedValue,
      updatedTouched,
    )
  }, [onChange, touched, value])

  useEffect(() => {
    if (isEdit || isReadOnly) return
    _.each(sections, (section) => {
      if (_.includes(touched, _.get(section, 'id'))) return
      const defaultSelectedSkuCodes = _.get(section, 'meta.defaultSelectedSkuCodes', [])
      const options = _.get(section, 'options', [])
      const preSelectedOptions = _.reject(
        _.map(defaultSelectedSkuCodes, ({ code: skuCode, quantity }) => (
          {
            ..._.find(options, { skuCode }),
            quantity,
          }
        )),
        ({ skuCode }) => _.isNil(skuCode),
      )
      if (_.isEmpty(preSelectedOptions)) return

      const sectionValue = _.reduce(preSelectedOptions, (result, option) => {
        switch (_.get(section, 'type')) {
          case 'quantity':
            result.push({
              [_.get(option, 'id')]: _.get(option, 'quantity'),
            })
            break;
          default:
            result.push(_.get(option, 'id'))
            break;
        }
        return result
      }, [])
      onSectionChange({
        ...section,
        value: sectionValue,
      })
    })
  }, [isEdit, isReadOnly, onSectionChange, sections, touched])

  const viewProps = {
    // changes,
    currentLocale,
    displayErrors,
    isReadOnly,
    onSectionChange,
    onSectionUpdate,
    sections,
    // onHeaderLayout,
    // onItemLayout: handleItemLayout,
    // initLayouts,
    ...otherProps,
  }

  return (
    <ProductAddonsView {...viewProps} />
  )
}

export default ProductAddonsController

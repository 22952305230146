import _ from 'lodash'
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
// import { Animated } from 'react-native'
import {
  cancelRequest,
  useStockCounters,
} from 'react-omnitech-api'
import AvailabilityInStoreView from './availability-in-store-view'
import {
  parseStockLevel,
} from '../../../../helpers'
// import Locale from '../../../services/locale'

const AvailabilityInStoreController = (props) => {
  const {
    productId,
    colorOptionId,
    sizeOptionId,
    siblingsProductCodes,
    skuId,
  } = props

  const {
    fetchStockCounters,
  } = useStockCounters()

  const [selectedStoreCode, setSelectedStoreCode] = useState()
  const [stockAvailibility, setStoreAvailability] = useState()
  const [loading, setLoading] = useState(false)
  const [inlineError, setInlineError] = useState()

  const onStoreChange = (item) => {
    setSelectedStoreCode(_.get(item, 'value'))
  }

  /**
   * fetchStockCountersApi
   * get reviews data from API
   */
  const fetchStockCountersApi = useCallback(async () => {
    if (!productId) return
    if (loading) return
    setLoading(true)
    setInlineError()
    try {
      // api call option
      const option = _.omitBy({
        skuActive: true,
        storeCodeEq: selectedStoreCode,
        pageSize: 999,
        ...(
          _.isEmpty(siblingsProductCodes)
            ? {
              productIds: productId,
              colorOptionIds: colorOptionId,
              sizeOptionIds: sizeOptionId,
              skuIds: skuId,
            }
            : {
              productCodeEq: siblingsProductCodes,
            }
        ),
      }, _.isNil)
      // call api
      const data = await fetchStockCounters(option)
      const stockCounters = _.get(data, 'stockCounters', [])
      setStoreAvailability(
        _.sum(
          _.map(
            _.filter(stockCounters, _.omitBy({ colorOptionId, sizeOptionId, skuId }, _.isNil)),
            ({ safeStockLevel = 0 }) => parseStockLevel(safeStockLevel),
          ),
        ),
      )
    } catch (error) {
      const errorMsg = _.get(error, 'generalError.message')
      if (!_.isEmpty(errorMsg)) {
        setInlineError(errorMsg)
      }
    } finally {
      setLoading(false)
    }
  }, [
    colorOptionId,
    fetchStockCounters,
    productId,
    selectedStoreCode,
    siblingsProductCodes,
    sizeOptionId,
    skuId,
  ])

  /**
   * load stock Availability
   */
  useEffect(() => {
    if (!_.isEmpty(selectedStoreCode)) {
      fetchStockCountersApi()
    }
  }, [fetchStockCountersApi, selectedStoreCode])

  useEffect(() => (
    function fetchStockCountersApiCleanUp() {
      cancelRequest.cancelAll([
        'fetchStockCounters',
      ])
    }
  ), [])

  const viewProps = {
    inlineError,
    loading,
    onStoreChange,
    selectedStoreCode,
    stockAvailibility,
    ...props,
  }

  return (
    <AvailabilityInStoreView {...viewProps} />
  )
}

export default AvailabilityInStoreController

/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button, CurrencyFormat,
  FavouriteButton,
  LoadingDots,
} from '../../../../ui'
import {
  useThemeConfig,
} from '../../../../hook'
import CartIcon from '../../../../assets/icons/icon_cart_white.svg'
import IconEmail from '../../../../assets/icons/icon_email.inline.svg'
import useStyles from './product-card-add-style'

const ProductCardAddView = ({
  addToCartInProgress,
  availableQuantity,
  availableForAddToCart,
  createBackInStockNotificationsInProgress,
  enableAddToCart,
  favourite,
  hideAddToCartButton,
  hideSizeVariant,
  isAddonsValid,
  isEdit,
  isOutOfStock,
  isReadOnly,
  onAddToCart,
  onBackInStockNotification,
  onFavouriteChange,
  productReady,
  productQuantity,
  selectedColorOptionId,
  selectedSizeOptionId,
  sellPrice,
  sizeOptions,
  sku,
}) => {
  // hook
  const { t } = useTranslation()
  const { getConfig } = useThemeConfig()
  const noPrice = _.isNull(sellPrice)
  const canSkuAddToCart = !(
    isOutOfStock
    || _.isEmpty(sku)
    || _.isNil(selectedColorOptionId)
    || _.isNil(selectedSizeOptionId)
    || noPrice
    || !isAddonsValid
    || !enableAddToCart
  )
  const noAvailableQuantity = availableQuantity <= 0
  const quantityExceededAvailableQuantity = productQuantity > availableQuantity
  const isAddToCartButtonDisabled = !canSkuAddToCart
    || addToCartInProgress
    || !productReady
    || noAvailableQuantity
    || quantityExceededAvailableQuantity
    || !availableForAddToCart
  const size = _.find(sizeOptions, { id: sku.sizeOptionId })
  const enableSubtotalPrice = getConfig('config.pages.product.enableSubtotalPrice', true) !== false
  const enableStockInfo = getConfig('config.pages.product.enableStockInfo', true) !== false
  const enableSkuCode = getConfig('config.pages.product.enableSkuCode', true) !== false
  const enableBackInStockNotification = getConfig('config.pages.product.enableBackInStockNotification', false)

  // get add to cart label
  const addToCartButtonState = useMemo(() => {
    let state = ''
    switch (true) {
      case !enableAddToCart:
        state = 'disabled'
        break
      case noPrice:
        state = 'unavailable'
        break
      case isOutOfStock && enableBackInStockNotification:
        state = 'backInStockNotification'
        break
      case isOutOfStock:
        state = 'outOfStock'
        break
      case noAvailableQuantity:
        state = 'noAvailableQuantity'
        break
      case quantityExceededAvailableQuantity:
        state = 'exceededAvailableQuantity'
        break
      case !availableForAddToCart:
        state = 'invalidSku'
        break
      case _.isNil(selectedSizeOptionId):
      case _.isEmpty(size):
        state = 'select'
        break
      case !isAddonsValid:
        state = 'selectAddons'
        break
      case !productReady:
      case addToCartInProgress:
        state = 'loading'
        break
      case isEdit:
        state = 'update'
        break
      default:
        state = 'normal'
        break
    }
    return state
  }, [
    addToCartInProgress,
    availableForAddToCart,
    enableAddToCart,
    isAddonsValid,
    isEdit,
    isOutOfStock,
    noAvailableQuantity,
    quantityExceededAvailableQuantity,
    noPrice,
    productReady,
    size,
    selectedSizeOptionId,
    t,
  ])
  const addToCartLabel = useMemo(() => (
    t('screens.product.cart.add', { context: addToCartButtonState, availableQuantity })
  ), [addToCartButtonState])

  const styles = useStyles()

  const handleFavouriteChange = (data) => {
    if (!_.isFunction(onFavouriteChange)) return
    onFavouriteChange({ colorOptionId: selectedColorOptionId, ...data })
  }

  return (
    <div className={styles.cardAdd}>
      <div className={styles.details}>
        {
          !hideSizeVariant && (
            <dl className={styles.line}>
              <dt className={styles.label}>{t('screens.product.cart.size')}</dt>
              <dd className={styles.value}>{_.get(sku, 'meta.detailedSize', _.get(size, 'name', '-'))}</dd>
            </dl>
          )
        }
        {
          enableSkuCode && (
            <dl className={styles.line}>
              <dt className={styles.label}>{t('screens.product.cart.code')}</dt>
              <dd className={styles.value}>{_.get(sku, 'code', '-')}</dd>
            </dl>
          )
        }
        {
          enableStockInfo && (
            <dl className={styles.line}>
              <dt className={styles.label}>{t('screens.product.cart.availability')}</dt>
              <dd className={styles.value}>
                {
                  productReady ? (
                    t(`screens.product.cart.${isOutOfStock ? 'outOfStock' : 'inStock'}`)
                  ) : (
                    <LoadingDots className={styles.loadingDots} />
                  )
                }
              </dd>
            </dl>

          )
        }
        {
          enableSubtotalPrice && (
            <dl className={styles.line}>
              <dt className={styles.label}>{t('screens.product.cart.price')}</dt>
              <dd className={styles.value}>
                {
                  !productReady && <LoadingDots className={styles.loadingDots} />
                }
                {
                  productReady && (
                    noPrice
                      ? '-'
                      : (
                        <CurrencyFormat
                          className={styles.price}
                          value={sellPrice}
                        />
                      ))
                }
              </dd>
            </dl>
          )
        }
      </div>
      {
        !isReadOnly
        && !hideAddToCartButton
        && (
          <div className={styles.buttonContainer}>
            {
              enableBackInStockNotification
              && addToCartButtonState === 'backInStockNotification'
                ? (
                  <Button
                    dark
                    iconInline
                    iconImage={IconEmail}
                    className={styles.backInStockNotificationButton}
                    iconClassName={styles.backInStockNotificationButtonIcon}
                    disabled={createBackInStockNotificationsInProgress}
                    loading={createBackInStockNotificationsInProgress}
                    classNameDisabled={styles.backInStockNotificationButtonDisabled}
                    text={addToCartLabel}
                    onClick={onBackInStockNotification}
                  />
                )
                : (
                  <Button
                    dark
                    iconImage={CartIcon}
                    className={styles.addToCartButton}
                    iconClassName={styles.addToCartButtonIcon}
                    disabled={isAddToCartButtonDisabled}
                    classNameDisabled={styles.addToCartButtonDisabled}
                    text={addToCartLabel}
                    onClick={() => _.isFunction(onAddToCart) && onAddToCart({ skuId: _.get(sku, 'id') })}
                  />
                )
            }
          </div>
        )
      }
      <div className={styles.buttonContainer}>
        <FavouriteButton
          iconButton
          label={t('screens.product.cart.favourites')}
          colorOptionID={selectedColorOptionId}
          favourite={favourite}
          onChange={handleFavouriteChange}
        />
      </div>
    </div>
  )
}

export default ProductCardAddView

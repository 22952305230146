/* eslint-disable no-unused-vars */
/* eslint-disable no-extra-semi */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import uuidv4 from 'uuid/v4'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import toInteger from 'lodash/fp/toInteger'
import moment from 'moment'
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import URI from 'urijs'
import {
  cancelRequest,
  useAuth,
  useBackInStockNotifications,
  useCategories,
  useCurrencies,
  useDepartments,
  useI18n,
  useProducts,
  useReviews,
  useSkus,
  useStores,
  useSystemSettings,
  useUser,
} from 'react-omnitech-api'
import {
  useAlert,
  useAnalytics,
  useCart,
  useDineInMiniCart,
  useLink,
  useLocation,
  useMiniCart,
  useModal,
  useOrderMethod,
  usePriceTemplate,
  useCompare,
  useThemeConfig,
} from '../../hook'
import {
  getNestedAddonSkus,
  getTopLevelCategoryCode,
  groupAddonsWithQuantity,
  isBrowser,
  isNotNullOrUndefined,
  isNullOrUndefined,
  parseStockLevel,
} from '../../helpers'
import ProductView from './product-view'
import useSku from '../../hook/use-sku'

const findProductAddonById = ({ productAddons = [], id }) => {
  const pa = _.find(productAddons, { id })
  if (_.isEmpty(pa)) { // Not found
    const nestedProductAddons = _.flatMapDeep(productAddons, ({ addons: _addons }) => _.map(_addons, 'productAddons'))
    if (_.isEmpty(nestedProductAddons)) return {}
    return findProductAddonById({ productAddons: nestedProductAddons, id })
  }
  return pa
}

function ProductController(props) {
  const {
    id,
    location,
  } = props
  const urlParams = useMemo(() => {
    let search = {}
    if (isBrowser()) {
      search = URI(location.href).search(true)
    }
    return search
  }, [location])

  const alert = useAlert()
  const { createBackInStockNotifications } = useBackInStockNotifications()
  const { categories } = useCategories()
  const { departments } = useDepartments()
  const { location: loc } = useLocation()
  const { navigate, recordNotFound } = useLink()
  const { user } = useUser()
  const {
    fetchStoreDateTimeInformation,
  } = useStores()
  const {
    compareData,
    addItemToCompare,
    hasMoreThan,
    isOpen,
    clearItemToCompare,
    enableComparisonEcom,
    maxNumberComparisonEcom,
    goToCompareProducts,
  } = useCompare()
  const { getSystemSetting } = useSystemSettings()
  const { currencies } = useCurrencies()
  const {
    isAllowToCheckoutAll,
    getMetaMenuFilterParams,
    getMetaMenuCodeFilterParamsAsync,
  } = useSku()
  const currency = _.find(currencies, { isBaseCurrency: true })
  const [product, setProduct] = useState({})
  const [initProductReady, setInitProductReady] = useState(false)
  const [productReady, setProductReady] = useState(false)
  const [fetchCartForEditReady, setFetchCartForEditReady] = useState(true)
  const [productAddons, setProductAddons] = useState([])
  const [addonsValue, setAddonsValue] = useState([])
  const [addonsTouched, setAddonsTouched] = useState([])
  const [isAddonsValid, setIsAddonsValid] = useState(false)
  const [siblings, setSiblings] = useState([])
  const [displayAddonsErrors, setDisplayAddonsErrors] = useState(false)
  const [productAddonsLoading, setProductAddonsLoading] = useState(false)
  const [productQuantity, setProductQuantity] = useState(1)
  const [reviews, setReviews] = useState({})
  const [nextReview, setNextReview] = useState(null)
  const [hasMoreReview, setHasMoreReview] = useState(false)
  const [reviewsReady, setReviewsReady] = useState(false)
  const [relatedProducts, setRelatedProducts] = useState([])
  const [relatedProductsReady, setRelatedProductsReady] = useState(false)
  const [relatedProductsPagination, setRelatedProductsPagination] = useState({})
  const [nextRelatedProducts, setNextRelatedProducts] = useState({})
  const [pageReady, setPageReady] = useState(false)
  const [addToCartInProgress, setAddToCartInProgress] = useState(false)
  const [createBackInStockNotificationsInProgress, setCreateBackInStockNotificationsInProgress] = useState(false)
  const [addToCartSuccessCount, setAddToCartSuccessCount] = useState(0)
  const [selectedColorOptionId, setSelectedColorOptionId] = useState(undefined)
  const [selectedSizeOptionId, setSelectedSizeOptionId] = useState(undefined)
  const [seoMetas, setSeoMetas] = useState([])
  const [storeMenuCodes, setStoreMenuCodes] = useState([])
  const [storeMenuCodesReady, setStoreMenuCodesReady] = useState(false)

  const fnbEnabled = getSystemSetting('features.fnb.enable')
  const reviewsEnabled = !getSystemSetting('hide_reviews', false)

  // prepare omnitech api
  const { t } = useTranslation()
  const { currentLanguage } = useI18n()
  const { auth } = useAuth()
  const { fetchProduct } = useProducts()
  const { fetchSkus } = useSkus()
  const { fetchReviewsByProductID, createReview } = useReviews()
  const {
    cartId,
    cart,
    createCart,
    fetchCart,
    getParams,
    getSkuTotalQuantityInCart,
    initCart,
    updateCart,
    updateStagingCart,
    stagingCart,
    inventoryStoreCode: useCartInventoryStoreCode,
  } = useCart()
  const { openMiniCart, closeMiniCart } = useMiniCart()
  const { openMiniCart: openDineInMiniCart, closeMiniCart: closeDineInMiniCart } = useDineInMiniCart()
  const { orderMethod, store } = useOrderMethod()
  const { getConfig, getContentGroup } = useThemeConfig()
  const { trackEvent, getProductParams } = useAnalytics()

  const modal = useModal()

  // enableBreadCrumb: enable by default;
  const enableBreadCrumb = getConfig('config.pages.product.enableBreadCrumb', true) !== false
  const enableRecentlyViewed = getConfig('config.pages.product.enableRecentlyViewed', false)

  const PRICE_TEMPLATE_KEY = _.get(usePriceTemplate(), 'code')
  const currentPage = `${_.get(loc, 'page', '/')}${_.get(loc, 'search', '')}`

  // local variable
  const seoTitle = _.get(product, 'title')
  const {
    code: pdpPageContentGroupCode,
    template: pdpPageContentGroupTemplate,
  } = useMemo(() => (
    getContentGroup('config.pages.pdp', 'ecom_pdp_page')
  ), [getContentGroup])
  const isLoginSuccess = useMemo(() => _.get(location, 'state.isLoginSuccess', false), [location])
  const { seoDescription, seoMeta } = useMemo(() => {
    let description = ''
    const meta = _.map(seoMetas, (data) => {
      const attribute = _.get(data, 'attribute') || {}
      // define a metakey to keep track of unique meta tags
      const metaKey = attribute.name || attribute.property
      if (metaKey === 'og:description') {
        description = attribute.content
      }
      // API sometimes return seo_meta keyword with property|content attributes
      // so need to clean-up to return name|content attributes
      if (metaKey === 'keywords' && _.isEmpty(data.attribute.name)) {
        attribute.name = 'keywords'
        delete attribute.property
      }
      return {
        ...attribute,
      }
    })
    return {
      seoMeta: meta,
      seoDescription: description,
    }
  }, [seoMetas])
  const seoLinks = [{
    rel: 'canonical',
    href: _.get(product, 'canonicalHref'),
  }]
  const breadcrumb = useMemo(() => {
    const topCategoryCode = getTopLevelCategoryCode({
      topLevelCategory: _.get(orderMethod, 'topLevelCategory'),
      storeCode: _.get(store, 'code', ''),
    })
    const category = _.find(categories, { id: _.get(product, 'categoryIds.0') })
    const all = {
      text: t('screens.product.breadcrumb.all'),
      url: '/products/',
    }
    const self = {
      text: _.get(product, 'title'),
    }
    let breadcrumbList = []
    const addCategory = ({
      departmentId,
      parentId,
      name,
      code,
    } = {}) => {
      const department = _.find(departments, { id: departmentId })
      const parent = _.find(categories, { id: parentId })
      breadcrumbList = _.concat([{
        text: name,
        url: `/products/?categoryCodeEq=${code}`,
      }], breadcrumbList)
      if (_.isPlainObject(parent)) {
        if (!_.isEmpty(topCategoryCode) && parent.code === topCategoryCode) {
          // skip top level category
        } else {
          addCategory(parent)
        }
      } else {
        const departmentCode = _.get(department, 'code')
        if (departmentCode) {
          breadcrumbList = _.concat(
            [{
              text: _.get(department, 'name'),
              url: `/products/?departmentCodeEq=${departmentCode}`,
            }],
            _.map(breadcrumbList, ({ url, ...others }) => ({
              url: `${url}&departmentCodeEq=${departmentCode}`,
              ...others,
            })),
          )
        }
      }
    }
    addCategory(category)
    return _.concat([all], breadcrumbList, [self])
  }, [product])

  const enableAddToCart = useMemo(() => _.get(orderMethod, 'enableAddToCart', true) !== false, [orderMethod])
  const hideAddToCartButton = useMemo(() => _.get(orderMethod, 'pdpHideAddToCartButton', false), [orderMethod])

  const orderMethodCode = useMemo(() => _.get(orderMethod, 'code'), [orderMethod])
  const orderMethodCommerceType = useMemo(() => _.get(orderMethod, 'commerceType'), [orderMethod])
  const orderMethodCommerceChannel = useMemo(() => _.get(orderMethod, 'commerceChannel'), [orderMethod])
  const inventoryStoreCode = useMemo(() => {
    if (orderMethodCode === 'dineInMenu') {
      return _.get(store, 'code')
    }
    return _.get(initCart, 'cartShipments.0.inventoryStore.code', '') || useCartInventoryStoreCode
  }, [initCart, orderMethodCode, store])
  const inventoryStoreId = useMemo(() => {
    if (orderMethodCode === 'dineInMenu') {
      return _.get(store, 'id')
    }
    return _.get(initCart, 'cartShipments.0.inventoryStore.id')
  }, [initCart, orderMethodCode, store])

  const isDineInMenuMode = useMemo(() => (
    orderMethodCode === 'dineInMenu'
  ), [orderMethodCode])

  const editGroupUuid = useMemo(() => (
    _.get(urlParams, 'groupUuid')
  ), [urlParams])

  const {
    cartLinePropertiesToEdit,
    preSelectedColorOptionId,
    preSelectedSizeOptionId,
  } = useMemo(
    () => ({
      cartLinePropertiesToEdit: _.filter(
        _.get(cart, 'cartLineProperties', []),
        ['groupUuid', editGroupUuid],
      ),
      preSelectedColorOptionId: _.toInteger(_.get(urlParams, 'colorOptionId')),
      preSelectedSizeOptionId: _.toInteger(_.get(urlParams, 'sizeOptionId')),
    }),
    [cart, editGroupUuid],
  )

  const isEdit = useMemo(() => (
    !_.isEmpty(editGroupUuid)
  ), [editGroupUuid])

  const isColorOptionExist = useCallback((productData, colorOptionId) => {
    const allColorOptions = _.get(productData, 'colorOptions', [])
    const targetColor = _.find(allColorOptions, { id: _.toInteger(colorOptionId) })
    return !_.isEmpty(targetColor)
  }, [urlParams])

  const convertSkusToProducts = (skus) => {
    const groupedSkus = _.groupBy(skus, 'product.id')
    // return products
    return _.map(groupedSkus, (_skus) => ({
      ..._.get(_.first(_skus), 'product', {}),
      skus: _.map(_skus, (sku) => _.omit(sku, ['product'])),
    }))
  }

  /**
   * get price ans stock level with cascade
   */
  const {
    onSale, originalPrice, stockLevel, sellPrice,
  } = useMemo(() => {
    if (!productReady) return {}

    let displayOriginalPrice
    let displaySellPrice
    let displayStockLevel

    switch (true) {
      // selected both color and size, use sku level
      case !_.isUndefined(selectedColorOptionId) && !_.isUndefined(selectedSizeOptionId): {
        const { skus = [] } = product
        const sku = _.find(skus, {
          colorOptionId: selectedColorOptionId,
          sizeOptionId: selectedSizeOptionId,
        })
        displayOriginalPrice = _.get(sku, 'originalPrice')
        displaySellPrice = _.get(sku, 'sellPrice')
        displayStockLevel = _.get(sku, 'stockLevel')
        break
      }
      // only selected color option, use color level
      case !_.isUndefined(selectedColorOptionId): {
        const { colorOptions = [] } = product
        const colorOption = _.find(colorOptions, {
          id: selectedColorOptionId,
        })
        displayOriginalPrice = _.get(colorOption, 'originalPrice')
        displaySellPrice = _.get(colorOption, 'sellPrice')
        displayStockLevel = _.get(colorOption, 'stockLevel')
        break
      }
      // use product level
      default: {
        displayOriginalPrice = _.get(product, 'originalPrice')
        displaySellPrice = _.get(product, 'sellPrice')
        displayStockLevel = _.get(product, 'stockLevel')
        break
      }
    }
    return {
      onSale: displayOriginalPrice !== displaySellPrice,
      originalPrice: displayOriginalPrice,
      sellPrice: displaySellPrice,
      stockLevel: parseStockLevel(displayStockLevel),
    }
  }, [productReady, product, selectedColorOptionId, selectedSizeOptionId])

  const availableQuantity = useMemo(() => {
    // [TODO] handle dine-in cart
    if (!productReady || !selectedSizeOptionId) return Infinity
    const cartLineProperties = _.get(initCart, 'cartLineProperties', [])

    const sku = _.find(_.get(product, 'skus', []), { colorOptionId: selectedColorOptionId, sizeOptionId: selectedSizeOptionId })
    const main = _.find(addonsValue, ['productAddonId', null])
                || { skuId: _.get(sku, 'id') }
    const mainSkuId = _.get(main, 'skuId')
    const siblingsStockLevel = _.get(_.find(siblings, { id: mainSkuId }), 'stockLevel')
    const mainStockLevel = _.isNumber(siblingsStockLevel)
      ? siblingsStockLevel
      : _.get(sku, 'stockLevel')
    const mainStockLevelInNumber = _.isNumber(mainStockLevel) ? mainStockLevel : Infinity
    const mainQuantityInCart = getSkuTotalQuantityInCart({ skuId: mainSkuId, reject: { groupUuid: editGroupUuid } })
    const totalItemsQuantityInCart = _.sumBy(_.filter(cartLineProperties, 'isMainProperty'), 'quantity')
    const others = _.filter(addonsValue, ({ productAddonId }) => !_.isNull(productAddonId))
    const stockLevelsAffectedByAddons = _.map(others, ({
      skuId,
      stockLevel: addonStockLevel,
      quantity: qty,
    }) => {
      const addonsQuantityInCart = getSkuTotalQuantityInCart({ skuId, reject: { groupUuid: editGroupUuid } })
      const remainStockLevel = addonStockLevel - addonsQuantityInCart
      return _.floor((remainStockLevel / qty) || 0)
    })
    const cartMaxNumOfQtyPerSku = _.get(initCart, 'cartMaxNumOfQtyPerSku', Infinity)
    const cartMaxNumOfQty = _.get(initCart, 'cartMaxNumOfQty', Infinity)
    const maxQty = _.min([
      cartMaxNumOfQtyPerSku - mainQuantityInCart,
      cartMaxNumOfQty - totalItemsQuantityInCart,
      mainStockLevelInNumber - mainQuantityInCart,
      ...stockLevelsAffectedByAddons,
    ])
    return maxQty
  }, [addonsValue, productReady, selectedSizeOptionId, product, siblings, initCart, editGroupUuid])

  const getAddonAvailableQuantity = useCallback((sku) => {
    if (productAddonsLoading || _.isEmpty(sku)) return Infinity
    const skuId = _.get(sku, 'id')
    const addOnStockLevel = parseStockLevel(_.get(sku, 'stockLevel'))
    const skuTotalQtyInCart = getSkuTotalQuantityInCart({ skuId, cart: initCart, reject: { groupUuid: editGroupUuid } })
    const stockLevelAffectedByMainQty = _.floor((addOnStockLevel - skuTotalQtyInCart) / productQuantity)
    return stockLevelAffectedByMainQty
  }, [productAddonsLoading, productAddons, initCart, productQuantity, editGroupUuid])

  // re-calculate checkout availability based on sku menu filters
  const availableForAddToCart = useMemo(() => {
    // return true if conditions are not ready in order to skip this checking
    if (
      !productReady
      || !selectedSizeOptionId
      || !selectedColorOptionId
      || !storeMenuCodesReady
      || productAddonsLoading
    ) return true
    // compose skus to check from main product
    let skus = _.filter(_.get(product, 'skus', []), {
      colorOptionId: selectedColorOptionId,
      sizeOptionId: selectedSizeOptionId,
    })
    // compose skus to check from addons
    if (!_.isEmpty(productAddons)) {
      const selectedAddons = _.filter(addonsValue, ({ quantity }) => quantity > 0)
      const selectedAddonSkus = _.filter(getNestedAddonSkus(productAddons), ({ id: productAddonSkuId }) => (
        _.includes(_.map(selectedAddons, 'skuId'), productAddonSkuId)
      ))
      if (!_.isEmpty(selectedAddonSkus)) {
        skus = _.uniqBy([
          ...skus,
          ...selectedAddonSkus,
        ], 'id')
      }
    }
    const exclusions = _.get(orderMethod, 'pdpSkuCheckExclusions', {})
    return isAllowToCheckoutAll({ skus, storeMenuCodes, exclusions })
  }, [
    addonsValue,
    initCart,
    isEdit,
    product,
    productAddons,
    productAddonsLoading,
    productReady,
    selectedColorOptionId,
    selectedSizeOptionId,
    siblings,
    storeMenuCodes,
    storeMenuCodesReady,
  ])

  /**
   * get Share URl and Share image URL
   */
  const {
    shareUrl,
    shareImageUrl,
  } = useMemo(() => {
    if (!initProductReady) return {}
    return {
      shareUrl: _.get(product, 'canonicalHref', location.href),
      shareImageUrl: _.get(product, 'colorOptions.0.images.0.versions.webMedium'),
    }
  }, [initProductReady, product])

  const fetchCartApi = useCallback(async () => {
    try {
      const option = {
        params: {
          schemaVersion: '2021-04-29',
          includes: [
            'cart_line_properties',
            'cart_line_properties.color_option',
            'cart_line_properties.sku',
            'cart_line_properties.product',
            'cart_line_properties.product_addon',
            'cart_shipments',
            'cart_shipments.inventory_store',

            'skus.color_option',
            'skus.product',
            'skus.size_option',
            'skus.stock_level',
          ].join(','),
          refresh_cart: true,
        },
      }
      // call api
      await fetchCart(option)
      setFetchCartForEditReady(true)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      if (generalError.code === 404) {
        recordNotFound(currentPage)
        return
      }
      alert.show(generalError.message)
    }
  }, [fetchCart])
  /**
   * fetchProductApi
   * get product data from API
   */
  const fetchProductApi = useCallback(async () => {
    try {
      // api call option
      setInitProductReady(false)
      setProductReady(false)
      setProduct({})
      const menuFiltersParam = getMetaMenuFilterParams({ prefix: 'skus' })
      const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync({ prefix: 'skus' })
      const option = {
        id,
        includes: [
          'category_ids',
          'color_option_variant_type',
          'color_options.images',
          'color_options.meta',
          'color_options',
          'default_color_option_id',
          'meta',
          'product_addon_ids',
          'product_detail_attributes',
          'reviews_summary',
          'reviews.user_default',
          'share_details',
          'size_option_variant_type',
          'size_options',
          'skus.color_option_id',
          'skus.meta',
          'skus.size_option_id',
          'skus',
        ].join(','),
        ...menuFiltersParam, // for 'product_addon_ids'
        ...menuCodeFiltersParam, // for 'product_addon_ids'
      }
      // call api
      const { product: data, seoMetas: seoMetasData } = await fetchProduct(option)
      setProduct(data)
      setSeoMetas(seoMetasData)
      setInitProductReady(true)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      if (generalError.code === 404) {
        recordNotFound(currentPage)
        return
      }
      alert.show(generalError.message)
    }
  }, [fetchProduct, id, currentLanguage])

  /**
   * fetchProductDetailsApi
   * get product details for non cached data
   */
  const fetchProductDetailsApi = useCallback(async () => {
    if (_.isEmpty(inventoryStoreCode)) return
    if (!initProductReady) return
    const menuFiltersParam = getMetaMenuFilterParams({ prefix: 'skus' })
    const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync({ prefix: 'skus' })
    try {
      // api call option
      const option = {
        id,
        includes: [
          'color_options.active_custom_labels',
          'color_options.favourite',
          'color_options.price_details',
          'color_options.stock_level',
          'color_options',
          'skus.price_details',
          'skus.stock_level',
          'skus',
          'stock_level',
        ].join(','),
        inventoryStoreCodeEq: inventoryStoreCode,
        priceStoreCodeEq: inventoryStoreCode,
        ...menuFiltersParam,
        ...menuCodeFiltersParam,
      }
      // call api
      const { product: data } = await fetchProduct(option)
      // Merge product data
      setProduct((p) => {
        const { colorOptions, skus } = p
        return {
          ...p,
          colorOptions: _.map(colorOptions, (co) => {
            const colorOptionDetail = _.find(_.get(data, 'colorOptions', []), { id: co.id })
            return _.merge({}, co, colorOptionDetail)
          }),
          skus: _.map(skus, (sku) => {
            const skuDetail = _.find(_.get(data, 'skus', []), { id: sku.id })
            return _.merge({}, sku, skuDetail)
          }),
          stockLevel: _.get(data, 'stockLevel', null),
        }
      })
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      if (generalError.code === 404) {
        recordNotFound(currentPage)
        return
      }
      alert.show(generalError.message)
    } finally {
      setProductReady(true)
    }
  }, [fetchProduct, id, inventoryStoreCode, initProductReady])

  const fetchProductAddonsApi = useCallback(async () => {
    try {
      // api call option
      setProductAddonsLoading(true)
      setProductAddons([])
      const menuFiltersParam = getMetaMenuFilterParams({ prefix: 'skus' })
      const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync({ prefix: 'skus' })
      const option = {
        id,
        schemaVersion: '2021-04-29',
        includes: [
          'addons.color_options',
          'addons.nested_product_addons',
          'addons.price_details',
          'addons.skus',
          'color_options.price_details',
          'color_options.stock_level',
          'color_options',
          'default_color_option_id',
          'meta',
          'product_addons.addons',
          'product_addons.meta',
          'product_addons',
          'size_options',
          'skus.color_option_id',
          'skus.meta',
          'skus.price_details',
          'skus.size_option_id',
          'skus.stock_level',
          'skus',
          // CP: required for hide variants to work!
          'color_option_variant_type',
          'size_option_variant_type',
        ].join(','),
        inventoryStoreCodeEq: inventoryStoreCode,
        priceStoreCodeEq: inventoryStoreCode,
        ...menuFiltersParam,
        ...menuCodeFiltersParam,
      }
      // call api
      const { product: data, seoMetas: seoMetasData } = await fetchProduct(option)
      setProductAddons(_.get(data, 'productAddons', []))
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      if (generalError.code === 404) {
        recordNotFound(currentPage)
        return
      }
      alert.show(generalError.message)
    } finally {
      setProductAddonsLoading(false)
    }
  }, [fetchProduct, id, inventoryStoreCode])

  const fetchSkusApi = useCallback(async (code) => {
    if (_.isEmpty(code)) return
    try {
      // api call option
      setProductAddonsLoading(true)
      setProductAddons([])
      const menuFiltersParam = getMetaMenuFilterParams()
      const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync()
      const option = {
        productCodeEq: code,
        pageCountless: true,
        schemaVersion: '2021-04-29',
        includes: [
          'addons.color_options',
          'addons.nested_product_addons',
          'addons.price_details',
          'addons.skus',
          'product_addons.addons',
          'product_addons.meta',
          'products.color_options',
          'products.meta',
          'products.product_addons',
          // 'skus.checkout_settings',
          'skus.color_option_id',
          'skus.meta',
          'skus.price_details',
          'skus.product',
          'skus.stock_level',
        ].join(','),
        inventoryStoreCodeEq: inventoryStoreCode,
        priceStoreCodeEq: inventoryStoreCode,
        ...menuFiltersParam,
        ...menuCodeFiltersParam,
      }
      // call api
      const { skus } = await fetchSkus(option)
      const products = convertSkusToProducts(skus)
      const sortedProducts = _.sortBy(products, 'meta.siblingsDisplaySequence')
      setSiblings(sortedProducts)
      const siblingsTitle = _.get(product, 'meta.siblingsTitle')
                                  || _.get(product, 'meta.addonHeader.title')
      setProductAddons([
        {
          id: null,
          addons: sortedProducts,
          meta: siblingsTitle ? {
            addonHeader: {
              title: siblingsTitle,
            },
          } : {},
          maximumSkuSelect: 1,
          minimumSkuSelect: 1,
          maximumPerSkuQuantity: 1,
        },
      ])
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      if (generalError.code === 404) {
        recordNotFound(currentPage)
        return
      }
      alert.show(generalError.message)
    } finally {
      setProductAddonsLoading(false)
    }
  }, [fetchSkus, inventoryStoreCode])

  const fetchStoreDateTimeInformationApi = async () => {
    try {
      // get store dateTimeInformation before valdation
      const { dateTimeInformation } = await fetchStoreDateTimeInformation({ id: inventoryStoreId })
      setStoreMenuCodes(_.get(dateTimeInformation, 'menuCodes', []))
    } catch (error) {
      // fail silently
    } finally {
      setStoreMenuCodesReady(true)
    }
  }

  /**
   * fetchReviewsApi
   * get reviews data from API
   */
  const fetchReviewsApi = useCallback(async () => {
    try {
      // api call option
      const option = {
        includes: [
          'user_default',
        ].join(','),
      }
      // call api
      const {
        reviews: data,
        pagination: paginationData,
        next,
      } = await fetchReviewsByProductID(id, option)
      setReviews(data)
      setHasMoreReview(_.isFunction(next))
      setNextReview(() => next)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      // TODO: send to rollbar
      // do nothing
      // alert.show(generalError.message)
    } finally {
      setReviewsReady(true)
    }
  }, [fetchReviewsByProductID, id])

  /**
   * createBackInStockNotificationsApi
   * create back in stock notifications
   */
  const createBackInStockNotificationsApi = useCallback(async () => {
    if (!createBackInStockNotificationsInProgress) return
    try {
      // api call option
      // {
      //   "back_in_stock_notification": {
      //     "store_id": 6,
      //     "target_item_type": "Sku",
      //     "target_item_id": "105",
      //     "all_store": false,
      //     "frontend_url_params": { }
      //   }
      // }
      const option = {
        targetItemType: 'Product',
        targetItemId: _.toString(_.get(product, 'id', id)),
        allStore: true,
        storeId: inventoryStoreId,
        frontendUrlParams: urlParams,
      }
      // call api
      await createBackInStockNotifications(option)
      // show modal dialog
      modal.open({
        title: t('screens.product.modalDialog.backInStockNotificationsSuccess.title'),
        message: t('screens.product.modalDialog.backInStockNotificationsSuccess.description'),
        buttons: [
          {
            text: t('screens.product.modalDialog.backInStockNotificationsSuccess.buttons.cancel'),
            onClick: () => modal.close(),
          },
        ],
      })
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      if (_.get(generalError, 'code') === 401) {
        const {
          page,
          search,
        } = loc
        const redirectUrl = `${page}${search}`
        navigate(
          '/login/',
          {
            state: {
              redirectUrl,
              // throw back selected order method when come back from login
              // callbackState: { preSelectedOrderMethod: selectedItem, isBlocker },
            },
            replace: true,
          },
        )
      } else {
        alert.show(generalError.message)
      }
    } finally {
      setCreateBackInStockNotificationsInProgress(false)
    }
  }, [createBackInStockNotifications, createBackInStockNotificationsInProgress])

  const recommendationOptions = useMemo(() => ({
    products: _.get(product, 'id'),
    colorOptions: selectedColorOptionId || _.get(product, 'defaultColorOptionId'),
    skus: _.get(_.find(product.skus, { colorOptionId: _.get(product, 'defaultColorOptionId') }), 'id'),
    trackEventOptions: {
      list: 'PDP Related Products',
    },
  }), [product, selectedColorOptionId])

  /**
   * apiCreateCart
   * Call API to create a new cart
   */
  async function apiCreateCart() {
    const options = {
      params: getParams({
        includeGroups: ['basic'],
      }),
    }
    return createCart(options)
  }

  const updateCartApi = async ({ cartId: _cartId, data = {} }) => {
    const includes = [
      'cart_line_properties',
      'cart_shipments',
      'cart_shipments.inventory_store',
    ]
    try {
      const updatedCartData = await updateCart({
        cartId: _cartId,
        payload: {
          data,
          batchUpdateMode: 2,
        },
        params: {
          includes,
          priceTemplate: PRICE_TEMPLATE_KEY,
          refreshCart: true,
        },
      })
      return updatedCartData
    } catch (error) {
      if (_.get(error, 'generalError.code') === 404) {
        const newCartData = await apiCreateCart()
        // retry
        return updateCartApi({ cartId: _.get(newCartData, 'cart.id'), data })
      }
      throw error
    }
  }

  const onProductQuantityChange = (value) => {
    setProductQuantity(value)
  }

  const onNextRelatedProducts = useCallback(async () => {
    setRelatedProductsReady(false)
    try {
      const { reviews: data, pagination: paginationData, next } = await nextRelatedProducts()
      setRelatedProducts((prev) => _.concat(prev, data))
      setRelatedProductsPagination(paginationData)
      setNextRelatedProducts(() => next)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      // TODO: send to rollbar
      // do nothing
      // alert.show(generalError.message)
    } finally {
      setReviewsReady(true)
    }
  }, [nextRelatedProducts])

  const onNextReview = useCallback(async () => {
    const option = {
      includes: [
        'user_default',
      ].join(','),
    }
    setReviewsReady(false)
    try {
      const { reviews: data, pagination: paginationData, next } = await nextReview(id)
      setReviews((prev) => _.concat(prev, data))
      setHasMoreReview(_.isFunction(next))
      setNextReview(() => next)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      // TODO: send to rollbar
      // do nothing
      // alert.show(generalError.message)
    } finally {
      setReviewsReady(true)
    }
  }, [nextReview, id])

  const onCreateReview = useCallback(async (review) => {
    if (!_.has(product, 'id')) return
    if (_.isEmpty(user)) {
      navigate(
        '/login/',
        {
          redirectUrl: _.get(product, 'canonicalHref'),
          replace: true,
        },
      )
    }

    if (reviewsReady) {
      setReviewsReady(false)
      try {
        const option = {
          includes: [
            'user_default',
          ].join(','),
        }
        const {
          review: data,
        } = await createReview({ data: { review: { productId: product.id, ...review } }, ...option })

        const userInclude = _.findIndex(reviews, ['user.id', _.get(data, 'user.id')])
        if (userInclude >= 0) {
          const reviewsClone = _.clone(reviews)
          _.remove(reviewsClone, (obj) => _.get(data, 'user.id') === _.get(obj, 'user.id'))
          setReviews(_.concat([data], reviewsClone))
        } else {
          setReviews((prev) => _.concat([data], prev))
        }
      } catch (error) {
        const generalError = _.get(error, 'generalError', {})
        alert.show(generalError.message)
      } finally {
        setReviewsReady(true)
      }
    }
  }, [createReview, reviewsReady, product])

  const onAddToCart = useCallback(async ({ skuId, quantity = 1 }) => {
    if (!skuId || !cartId || addToCartInProgress) return
    // FL: Force user login before add item to cart when takeAway
    //    fnb cart is isolated. unable to merge cart after user login
    if (
      orderMethodCommerceType === 'takeAway'
      && _.isEmpty(_.toString(_.get(auth, 'userId') || ''))
    ) {
      navigate(
        '/login/',
        {
          state: {
            redirectUrl: _.get(loc, 'page'),
          },
          replace: true,
        },
      )
      return
    }
    setAddToCartInProgress(true)
  }, [addToCartInProgress, cartId, updateCart])

  const onBackInStockNotification = () => {
    setCreateBackInStockNotificationsInProgress(true)
  }

  const onColorChange = useCallback((colorOptionId) => {
    setSelectedColorOptionId(colorOptionId)
    setSelectedSizeOptionId(undefined)
  }, [])
  const onSizeChange = useCallback((sizeOptionId) => {
    setSelectedSizeOptionId(sizeOptionId)
  }, [])

  const onAddonsChange = (newValue, touched) => {
    setAddonsValue(newValue)
    setAddonsTouched(touched)
  }

  const onAddonsValidate = (valid) => {
    setIsAddonsValid(valid)
  }

  /**
   * Update product when favourite changed
   */
  const onFavouriteChange = ({ colorOptionId, favourite }) => {
    setProduct((origProduct) => {
      const origColorOptions = _.get(origProduct, 'colorOptions', [])
      const colorOptionIndex = _.findIndex(origColorOptions, { id: colorOptionId })
      const updatedColorOptions = _.set(origColorOptions, [colorOptionIndex, 'favourite'], favourite)
      return {
        ...origProduct,
        colorOptions: updatedColorOptions,
      }
    })
  }

  function handleAddToCompare(item, isInStore = false) {
    const objProduct = {
      id: _.get(item, 'id', ''),
      productId: isInStore ? _.get(item, 'productId') : _.get(item, 'product.id'),
      image: isInStore ? _.get(item, 'image')
        : _.get(item, 'colorOption.defaultImage.versions.webThumb', ''),
    }
    addItemToCompare(objProduct)
  }

  function onTrackEvent(objProduct) {
    const colorOptionId = flow(
      get('color'),
      toInteger,
    )(urlParams)
    const colorOption = _.find(objProduct.skus, {
      colorOptionId,
    })

    trackEvent('viewProductDetail', {},
      {
        product,
        price: _.get(colorOption, 'sellPrice'),
        quantity: 1,
        skuCode: _.get(colorOption, 'id'),
        title: seoTitle,
      })
  }

  function onTrackAddToCart(skuId) {
    const sku = _.find(product.skus, {
      id: skuId,
    })

    trackEvent('customerAddToCart',
      {
        eventValue: 1,
        getSystemSetting,
      },
      {
        product,
        price: _.get(sku, 'sellPrice'),
        quantity: 1,
        skuCode: _.get(sku, 'code'),
        title: seoTitle,
        value: _.get(cart, 'priceDetails.cartTotalPrice'),
        currencyCode: _.get(cart, 'currencyCode'),
      })
  }

  function handleClickTrackEvent(eventName, productSuggestions) {
    trackEvent(eventName, {}, { product: productSuggestions })
  }

  const storeRecentlyViewed = useCallback(() => {
    if (!enableRecentlyViewed) return
    if (!initProductReady) return
    try {
      const data = JSON.parse(window.localStorage.getItem('recentlyViewedProducts'))
      const recentlyViewedProducts = _.get(data, orderMethodCommerceChannel, [])
      const productId = _.get(product, 'id')
      const updatedList = [
        {
          colorOptionId: selectedColorOptionId,
          productId,
          url: _.get(product, 'canonicalHref'),
          updatedAt: moment().format(),
        },
        ..._.take(
          _.reject(recentlyViewedProducts, { productId }),
          100, // limit to 100 items
        ),
      ]
      window.localStorage.setItem(
        'recentlyViewedProducts',
        JSON.stringify({
          ...data,
          [orderMethodCommerceChannel]: updatedList,
        }),
      )
    } catch (error) {
      // fail silently
    }
  }, [
    enableRecentlyViewed,
    orderMethodCommerceChannel,
    initProductReady,
    id,
    selectedColorOptionId,
  ])

  useEffect(storeRecentlyViewed, [storeRecentlyViewed])

  useEffect(() => {
    if (
      _.isEmpty(product)
      || !productReady
    ) return
    // Track event
    onTrackEvent(product)
  }, [product, productReady])

  /**
   * load product addons when product ready
   */
  useEffect(() => {
    if (initProductReady) {
      // Fetch Product Addons
      const siblingsProductCodes = _.get(product, 'meta.siblingsProductCodes', [])
      const productAddonIds = _.get(product, 'productAddonIds', [])
      // If contain siblings
      // Skip get siblings on edit mode
      if (!_.isEmpty(siblingsProductCodes) && _.isEmpty(siblings) && !isEdit) {
        fetchSkusApi(siblingsProductCodes)
      } else if (!_.isEmpty(productAddonIds)) {
        fetchProductAddonsApi()
      } else {
        setIsAddonsValid(true)
      }
    }
  }, [initProductReady, isEdit, product])

  /**
   * Clean SizeOtionId and Color Option ID every time ID product change
   */
  useEffect(() => {
    setSelectedSizeOptionId(undefined)
    setSelectedColorOptionId(undefined)
  }, [id])

  /**
   * load product when page loaded
   */
  useEffect(() => {
    fetchProductApi()
    return function fetchProductApiCleanUp() {
      cancelRequest.cancelAll([
        'fetchProduct',
        'fetchSkus',
      ])
    }
  }, [fetchProductApi])

  /**
   * load product details after product ready
   */
  useEffect(() => {
    fetchProductDetailsApi()
  }, [fetchProductDetailsApi])

  useEffect(() => {
    if (isEdit && !isDineInMenuMode) {
      setFetchCartForEditReady(false)
      // Fetch cart to get more information for cart line properties when in edit mode
      fetchCartApi()
    }
  }, [isEdit, isDineInMenuMode])

  /**
   * Init default color/size option
   */
  useEffect(() => {
    if (
      _.isEmpty(product)
      || isEdit
      || isNotNullOrUndefined(selectedColorOptionId)
    ) return

    // Color Option
    // check color from url params is exist, otherwise use default
    let colorOptionId = flow(
      get('color'),
      toInteger,
    )(urlParams)

    if (!colorOptionId || !isColorOptionExist(product, colorOptionId)) {
      colorOptionId = _.get(product, 'defaultColorOptionId')
    }
    setSelectedColorOptionId(colorOptionId)
  }, [urlParams, product, isEdit])
  /**
   * Auto select size option if only one size Option when color option changed
   */
  useEffect(() => {
    if (
      _.isEmpty(product)
      || !productReady
      || isEdit
      || isNullOrUndefined(selectedColorOptionId)
    ) return
    // Size Option
    const sizeOptions = _.get(product, 'sizeOptions', [])
    const firstSizeOptionId = _.get(sizeOptions, '0.id')
    const firstSizeOptionStockLevel = _.get(
      _.find(product.skus, {
        sizeOptionId: firstSizeOptionId,
        colorOptionId: selectedColorOptionId,
      }),
      'stockLevel',
      Infinity,
    )
    const isOutOfStock = parseStockLevel(firstSizeOptionStockLevel) <= 0
    if (sizeOptions.length === 1 && !isOutOfStock) {
      setSelectedSizeOptionId(firstSizeOptionId)
    } else {
      setSelectedSizeOptionId(undefined)
    }
  }, [selectedColorOptionId, product, productReady, isEdit])
  /**
   * Auto select color/size option if in edit mode
   */
  useEffect(() => {
    if (initProductReady && isEdit) {
      if (_.isNumber(preSelectedColorOptionId)) {
        setSelectedColorOptionId(preSelectedColorOptionId)
      }
      if (_.isNumber(preSelectedSizeOptionId)) {
        setSelectedSizeOptionId(preSelectedSizeOptionId)
      }
    }
  }, [isEdit, initProductReady, preSelectedColorOptionId, preSelectedSizeOptionId])
  /**
   * Auto select Addons if in edit mode
   */
  useEffect(() => {
    if (isEdit && !_.isEmpty(productAddons)) {
      const defaultValue = _.reduce(
        cartLinePropertiesToEdit,
        (result, cartLineProperty) => {
          const {
            colorOption,
            identifierUuid,
            isMainProperty,
            parentIdentifierUuid,
            productAddon,
            quantity: qty,
            sku: clpSku,
            customerRemark,
          } = cartLineProperty

          if (isMainProperty) return result

          const productAddonId = _.get(productAddon, 'id')
          const skuId = _.get(clpSku, 'id')
          const skuCode = _.get(clpSku, 'code')
          const pa = findProductAddonById({ productAddons, id: productAddonId })
          const addonId = _.get(_.find(_.get(pa, 'addons', []), ({ skus = [] }) => _.includes(_.map(skus, 'id'), skuId)), 'id')
          const title = _.get(colorOption, 'name')

          result.push({
            productAddonId,
            skuId,
            skuCode,
            stockLevel: _.get(clpSku, 'stockLevel'),
            addonId,
            title,
            identifierUuid,
            parentIdentifierUuid,
            quantity: qty,
            customerRemark,
          })
          return result
        },
        [],
      )
      const defaultValueWithQuantity = groupAddonsWithQuantity(defaultValue)
      const defaultValueWithQuantityIndex = _.reduce(defaultValueWithQuantity, (result, defaultValueItem) => {
        const quantityIndex = _.size(
          _.filter(result, _.pick(defaultValueItem, [
            'addonId',
            'productAddonId',
            'skuId',
          ])),
        )
        result.push({
          ...defaultValueItem,
          quantityIndex,
        })
        return result
      }, [])

      const defaultValueWithParents = _.reduce(defaultValueWithQuantityIndex, (result, defaultValueItem) => {
        const parentItem = _.find(
          result,
          ({ groupedIdentifierUuid = [] }) => _.includes(groupedIdentifierUuid, _.get(defaultValueItem, 'parentIdentifierUuid')),
        )
        result.push({
          ...defaultValueItem,
          parents: _.isEmpty(parentItem)
            ? []
            : [
              ..._.get(parentItem, 'parents', []),
              {
                ..._.pick(parentItem, [
                  'addonId',
                  'productAddonId',
                  'skuId',
                ]),
                quantityIndex: _.get(defaultValueItem, 'quantityIndex', 0),
              },
            ],
        })
        return result
      }, [])
      setAddonsValue(
        _.map(defaultValueWithParents, (defaultValueItem) => _.pick(defaultValueItem, [
          'addonId',
          'customerRemark',
          'parents',
          'productAddonId',
          'quantity',
          'quantityIndex',
          'skuCode',
          'skuId',
          'stockLevel',
          'title',
        ])),
      )
      setAddonsTouched(
        _.uniq(_.map(defaultValueWithParents, 'productAddonId')),
      )
    }
  }, [isEdit, product, productAddons, cartLinePropertiesToEdit]) // TODO: Check `product` dependance

  /**
   * set default quantity on edit mode
   */
  useEffect(() => {
    if (isEdit && !_.isEmpty(cartLinePropertiesToEdit)) {
      setProductQuantity(_.get(_.find(cartLinePropertiesToEdit, { isMainProperty: true }), 'quantity'))
    }
  }, [isEdit, cartLinePropertiesToEdit])

  /**
   * load review when product loaded
   */
  useEffect(() => {
    if (initProductReady && reviewsEnabled) {
      fetchReviewsApi()
    }

    return function fetchReviewsApiCleanUp() {
      cancelRequest.cancelAll([
        'fetchReviewsByProductID',
      ])
    }
  }, [fetchReviewsApi, initProductReady, reviewsEnabled])

  /**
   * All Api calls ready
   */
  useEffect(() => {
    setPageReady(productReady && reviewsReady && relatedProductsReady)
  }, [productReady, reviewsReady, relatedProductsReady])

  /**
   * Handle add to cart
   */
  useEffect(() => {
    if (addToCartInProgress) {
      setDisplayAddonsErrors(false)
      // Start Add Items to cart
      const sku = _.find(_.get(product, 'skus', []), { colorOptionId: selectedColorOptionId, sizeOptionId: selectedSizeOptionId })
      const main = _.find(addonsValue, ['productAddonId', null])
                  || {
                    skuId: _.get(sku, 'id'),
                    skuCode: _.get(sku, 'code'),
                    productAddonId: null,
                    addonId: _.get(product, 'id'), // for item update
                    quantityIndex: 0,
                    title: _.get(product, 'title'), // for staging cart display
                  }
      const others = _.filter(addonsValue, ({ productAddonId }) => !_.isNull(productAddonId))
      const commonActionProps = {
        actionType: 'update_cart_line_property',
        quantityMode: 'fixed',
      }
      const groupUuid = uuidv4()
      const combinedActions = [
        {
          ...main,
          ...commonActionProps,
          groupUuid,
          parentIdentifierUuid: null,
          quantity: productQuantity,
          // salesRemark: noteToChefText
        },
        ..._.flatMap(others, ({ quantity: qty, ...addon }) => (
          _.times(qty, (quantityIndex) => ({
            ...addon,
            ...commonActionProps,
            groupUuid,
            quantity: 1,
            quantityIndex,
          }))
        )),
      ]

      // Sort actions by parents count to make sure parentIdentifierUuid can be found
      const sortedActions = _.sortBy(combinedActions, ({ parents = [] }) => _.size(parents))

      const actionsWithIdentifierUuid = _.reduce(sortedActions, (result, action) => {
        const {
          parents = [],
          productAddonId,
          groupUuid: actionGroupUuid,
        } = action
        const parent = _.last(parents)
        const actionWithIdentifierUuid = {
          ...action,
          identifierUuid: uuidv4(),
        }
        if (_.isNull(productAddonId)) { // main
          result.push(actionWithIdentifierUuid)
        } else if (_.isEmpty(parent)) {
          result.push({
            ...actionWithIdentifierUuid,
            parentIdentifierUuid: _.get(_.find(result, { productAddonId: null, groupUuid: actionGroupUuid }), 'identifierUuid'),
          })
        } else {
          result.push({
            ...actionWithIdentifierUuid,
            parentIdentifierUuid: _.get(
              _.find(result, {
                ..._.pick(parent, [
                  'productAddonId',
                  'addonId',
                  'skuId',
                  'quantityIndex',
                ]),
                groupUuid,
              }),
              'identifierUuid',
            ),
          })
        }
        return result
      }, [])

      if (orderMethodCommerceType === 'dineIn') {
        updateStagingCart({
          actions: actionsWithIdentifierUuid,
        })
        setAddToCartInProgress(false)
        setAddonsValue([])
        setAddonsTouched([])
        setProductQuantity(1)
        setAddToCartSuccessCount((prev) => (prev + 1))
        openDineInMiniCart()
        return
      }

      const actions = _.map(
        actionsWithIdentifierUuid,
        (action) => (
          _.pick(action, [
            'actionType',
            'groupUuid',
            'identifierUuid',
            'parentIdentifierUuid',
            'productAddonId',
            'quantity',
            'quantityMode',
            'skuId',
            'customerRemark',
          ])
        ),
      )

      // Remove Previous items when updating cart item
      const actionToRemoveMain = isEdit
        ? [{
          ...commonActionProps,
          quantity: 0,
          productAddonId: null,
          skuId: _.toInteger(_.get(_.find(cartLinePropertiesToEdit, 'isMainProperty'), 'sku.id')),
          ..._.pick(_.find(cartLinePropertiesToEdit, 'isMainProperty'), [
            'groupUuid',
            'identifierUuid',
            'parentIdentifierUuid',
          ]),
        }]
        : []

      // FL: For Non-fnb use `add_main_cart_line_property` as actionType
      const updateCartPayloadData = (
        !fnbEnabled
        && !_.has(product, 'meta.siblingsProductCodes') // NO siblings
        && _.isEmpty(_.get(product, 'productAddonIds', [])) // NO addons
      )
        ? {
          actions: [{
            actionType: 'add_main_cart_line_property',
            skuId: _.get(main, 'skuId'),
            quantity: productQuantity,
            quantityMode: 'increment',
          }],
        }
        : {
          actions: [
            ...actionToRemoveMain,
            ...actions,
          ],
        }

      if (orderMethodCommerceType === 'dineIn') {
        updateStagingCart(updateCartPayloadData)
        setAddToCartInProgress(false)
        return
      }

      // eslint-disable-next-line semi-style
      ;(async () => {
        try {
          closeMiniCart()
          const {
            cart: data,
          } = await updateCartApi({
            cartId,
            data: updateCartPayloadData,
          })
          if (isEdit) {
            navigate('/cart/')
          } else {
            setAddonsValue([])
            setAddonsTouched([])
            setProductQuantity(1)
            setAddToCartSuccessCount((prev) => (prev + 1))
            openMiniCart()
          }
          // FL: [TODO] track add to cart item includes addons and sibling
          onTrackAddToCart(_.get(main, 'skuId'))
        } catch (error) {
          const batchActionError = _.get(error, 'batchActionErrors[0]', {})
          const generalError = _.get(error, 'generalError', {})
          const message = _.isEmpty(batchActionError.message)
            ? generalError.message
            : batchActionError.message
          alert.show(message)
        } finally {
          setAddToCartInProgress(false)
        }
      })()

      // [FL] Add Dine-in support
      // if (isDineIn) {
      //   const cartParams = updateCartFnbParams({
      //     id: _.get(dineInCart, 'id'),
      //     actions: _.compact([
      //       // Remove Previous items when updating cart item
      //       !_.isEmpty(dineInCartItem) ? {
      //         actionType: 'update_cart_line_property',
      //         skuId: _.toInteger(_.get(dineInCartItem, 'skuId')),
      //         quantity: 0,
      //         quantityMode: 'fixed',
      //         productAddonId: null,
      //         groupUuid: _.get(dineInCartItem, 'uuid')
      //       } : undefined,
      //       ...actionsWithIdentifierUuid,
      //     ]),
      //     refresh: !_.isEmpty(cartItem),
      //   })
      //   onAddToCartSuccess(() => {
      //     refreshDineInStagingAction(cartParams)
      //     setAddToCartInProgress(false)
      //     navigation.dispatch(NavigationActions.back())
      //   })
      // } else {
    //   CheckoutService.updateCart({
    //     cart,
    //     actions: _.compact([
    //       // Remove Previous items when updating cart item
    //       !_.isEmpty(cartItem) ? {
    //         actionType: 'update_cart_line_property',
    //         skuId: _.toInteger(_.get(cartItem, 'sku.id')),
    //         quantity: 0,
    //         quantityMode: 'fixed',
    //         productAddonId: null,
    //         groupUuid: _.get(cartItem, 'groupUuid'),
    //         identifierUuid: _.get(cartItem, 'identifierUuid'),
    //         parentIdentifierUuid: _.get(cartItem, 'parentIdentifierUuid'),
    //       } : undefined,
    //       ...actions,
    //       !_.isEmpty(pickupStore) && !_.isEmpty(_.get(shoppingCart, 'cartShipments.0.id')) ? {
    //         actionType: 'update_cart_shipment',
    //         id: _.get(shoppingCart, 'cartShipments.0.id'),
    //         deliveryType,
    //         pickupStoreId: pickupStore.id,
    //       } : undefined,
    //     ]),
    //   })
    //     .then((response) => {
    //       if (!_.isEmpty(cartItem)) {
    //         onUpdateCartSuccess(response, () => {
    //           refreshCartAction(camelCaseKeys(response.cart, {deep: true}))
    //           navigation.dispatch(NavigationActions.back())
    //         })
    //       } else {
    //         onAddToCartSuccess(() => {
    //           refreshCartAction(camelCaseKeys(response.cart, {deep: true}))
    //           navigation.dispatch(NavigationActions.back())
    //         })
    //       }
    //     })
    //     .catch((errors) => {
    //       alertErrorMessage(_.get(errors, 'generalError.message'))
    //     }).finally(() => {
    //       setAddToCartInProgress(false)
    //     })
    // }
    }
  }, [addToCartInProgress])

  useEffect(() => {
    if (inventoryStoreId) {
      setStoreMenuCodesReady(false)
      fetchStoreDateTimeInformationApi()
    }
  }, [inventoryStoreId])

  useEffect(() => {
    createBackInStockNotificationsApi()
  }, [createBackInStockNotificationsApi])

  const viewProps = {
    addToCartSuccessCount,
    availableQuantity,
    availableForAddToCart,
    pdpPageContentGroupCode,
    pdpPageContentGroupTemplate,
    addToCartInProgress,
    breadcrumb,
    createBackInStockNotificationsInProgress,
    hasMoreReview,
    currency,
    enableAddToCart,
    enableBreadCrumb,
    enableRecentlyViewed,
    fetchCartForEditReady,
    fnbEnabled,
    getAddonAvailableQuantity,
    hideAddToCartButton,
    onSale,
    originalPrice,
    pageReady,
    product,
    productReady,
    productAddons,
    productAddonsLoading,
    productQuantity,
    siblings,
    addonsTouched,
    addonsValue,
    initProductReady,
    isAddonsValid,
    isEdit,
    isReadOnly: isDineInMenuMode,
    recommendationOptions,
    reviewsEnabled,
    relatedProducts,
    relatedProductsPagination,
    relatedProductsReady,
    reviews,
    reviewsReady,
    selectedColorOptionId,
    selectedSizeOptionId,
    sellPrice,
    seoDescription,
    seoMeta,
    seoTitle,
    seoLinks,
    stockLevel,
    shareUrl,
    shareImageUrl,
    user,
    hasMoreThan,
    compareData,
    enableComparisonEcom,
    maxNumberComparisonEcom,
    isOpen,
    goToCompareProducts,
    onAddonsChange,
    onAddonsValidate,
    onBackInStockNotification,
    onColorChange,
    onSizeChange,
    onNextReview,
    onCreateReview,
    onNextRelatedProducts,
    onAddToCart,
    onAddToCompare: handleAddToCompare,
    onClearCompare: clearItemToCompare,
    onClickTrackEvent: handleClickTrackEvent,
    onProductQuantityChange,
    onFavouriteChange,
  }

  return (
    <ProductView {...viewProps} />
  )
}

export default ProductController

import _ from 'lodash'
import React, { useCallback } from 'react'

import ProductAddonSection from '../product-addon-section'
// import styles from './product-addons-styles'

const Section = ({
  item,
  onChange,
  onUpdate,
  ...rest
}) => {
  const handleChange = useCallback((data) => {
    if (_.isFunction(onChange)) onChange({ ...data, ...item })
  }, [item, onChange])
  const handleUpdate = useCallback((data) => {
    if (_.isFunction(onUpdate)) onUpdate({ ...data, ...item })
  }, [item, onUpdate])
  return (
    <ProductAddonSection
      {...item}
      onChange={handleChange}
      onUpdate={handleUpdate}
      {...rest}
    />
  )
}

const ProductAddonsView = (props) => {
  const {
    currency,
    disabled,
    onSectionChange,
    onSectionUpdate,
    sections,
  } = props

  return (
    <>
      {
        _.map(sections, (item) => (
          <Section
            key={`section_${_.get(item, 'id')}_${_.get(_.last(_.get(item, 'parents', [])), 'quantityIndex', '0')}`}
            item={item}
            onChange={onSectionChange}
            onUpdate={onSectionUpdate}
            currency={currency}
            disabled={disabled}
          />
        ))
      }
    </>
  )
}

export default ProductAddonsView

import _ from 'lodash'
import React, { useMemo } from 'react'
import {
  useStores,
} from 'react-omnitech-api'
import { useTranslation } from 'react-i18next'
import { components } from 'react-select'
import { useThemeConfig } from '../../../../hook/use-theme-config'
import useOrderMethod from '../../../../hook/use-order-method'
import combineClassNames from '../../../../helpers/combineClassNames'
import Dropdown from '../../../../ui/dropdown'
import { customStylesOnlyText } from '../../../../ui/dropdown/custom-styles'

import useStyles from './availability-in-store-styles'
import { getAddressLinesFromStore } from '../../../../helpers'

const AvailabilityInStoreView = (props) => {
  const { t } = useTranslation()
  const {
    inlineError,
    productId,
    loading,
    onStoreChange,
    selectedStoreCode,
    stockAvailibility,
    className,
    progressLabelClassName,
    progressBarClassName,
  } = props
  const {
    stores,
  } = useStores()
  const { getConfig } = useThemeConfig()
  const { orderMethod } = useOrderMethod()
  const styles = useStyles()

  const stockAvailabilityLevels = _.sortBy(getConfig('config.pages.product.stockAvailability.levels', [10, 50, 100])) // low, medium, high availability
  const enableStoreAddress = getConfig('config.pages.product.stockAvailability.enableStoreAddress', false)
  let stockAvailabilityState = 'full'
  if (_.inRange(stockAvailibility, 1, _.first(stockAvailabilityLevels))) {
    stockAvailabilityState = 'low'
  } else if (
    _.inRange(
      stockAvailibility,
      _.first(stockAvailabilityLevels),
      _.nth(stockAvailabilityLevels, 1),
    )
  ) {
    stockAvailabilityState = 'medium'
  } else if (
    _.inRange(
      stockAvailibility,
      _.nth(stockAvailabilityLevels, 1),
      _.nth(stockAvailabilityLevels, 2),
    )
  ) {
    stockAvailabilityState = 'high'
  } else if (stockAvailibility === 0) {
    stockAvailabilityState = 'outOfStock'
  }

  const storeList = useMemo(() => {
    const list = _.map(
      stores,
      (store) => {
        const { name, code } = store
        return ({ value: code, label: name, addressLines: getAddressLinesFromStore(store) })
      },
    )
    const enableStores = _.get(orderMethod, 'enableStores')
    if (!_.isArray(enableStores)) return list
    const filteredList = _.filter(
      list,
      ({ value }) => _.includes(enableStores, value),
    )
    return filteredList
  }, [stores])

  const selectedStore = useMemo(() => (
    _.find(storeList, { value: selectedStoreCode })
  ), [selectedStoreCode, storeList])

  const desc = t('screens.product.stockAvailability.desc', { context: stockAvailabilityState })
  const stockLevelLabel = t(
    'screens.product.stockAvailability.storePicker.stockLevel',
    { context: stockAvailabilityState, count: stockAvailibility },
  )

  return (
    <div className={combineClassNames([styles.container, className])}>
      <Dropdown
        label={t('screens.product.stockAvailability.storePicker.label')}
        placeholder={t('screens.product.stockAvailability.storePicker.placeholder')}
        values={selectedStore}
        options={storeList}
        onChange={onStoreChange}
        customStyles={customStylesOnlyText}
        loading={loading}
        className={styles.dropdown}
        labelClassName={styles.dropdownLabel}
        components={{
          ...(enableStoreAddress ? {
            Option: (p) => (
              <components.Option {...p}>
                <p className={styles.dropdownOptionStoreName}>{p.label}</p>
                {
                  _.map(_.get(p, 'data.addressLines', []), (line) => (
                    <div
                      key={`product.stockAvailability.storePicker.option__${p.value}`}
                      className={styles.dropdownOptionStoreAddress}
                      dangerouslySetInnerHTML={{ __html: line }}
                    />
                  ))
                }
              </components.Option>
            ),
          } : {}),
          SingleValue: ({
            children,
            ...p
          }) => (
            <components.SingleValue {...p}>
              {children}
              {
                !_.isEmpty(stockLevelLabel) && (
                  <span
                    className={combineClassNames([
                      styles.stockLevelLabel,
                      styles[_.camelCase(`stockLevelLabel-${stockAvailabilityState}`)],
                    ])}
                  >
                    {stockLevelLabel}
                  </span>
                )
              }
            </components.SingleValue>
          ),
        }}
      />
      {
        selectedStoreCode && (
          <div className={styles.progressContainer}>
            <label
              htmlFor={`stockAvailability_${productId}`}
              className={combineClassNames([
                styles.label,
                progressLabelClassName,
              ])}
            >
              {t('screens.product.stockAvailability.progress.label')}
            </label>
            <progress
              id={`stockAvailability_${productId}`}
              className={
                combineClassNames([
                  styles.progress,
                  styles[_.camelCase(`progress-${stockAvailabilityState}`)],
                  progressBarClassName,
                ])
              }
              max={_.max(stockAvailabilityLevels)}
              value={
                !loading && _.clamp(
                  stockAvailibility,
                  _.max(stockAvailabilityLevels),
                )
              }
            >
              {`${_.get(selectedStore, 'label')}: ${stockAvailibility}`}
            </progress>
            {
              !_.isEmpty(desc)
              && !loading
              && (
                <p className={styles.desc}>{desc}</p>
              )
            }
            {
              !_.isEmpty(inlineError)
              && (
                <p className={styles.inlineError}>{inlineError}</p>
              )
            }
          </div>
        )
      }
    </div>
  )
}

export default AvailabilityInStoreView

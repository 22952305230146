/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
// import {
//   CurrencyFormat, CustomLables, SocialMedia, Link,
// } from 'gatsby-theme-omnitech-core/src/ui'
import {
  CheckboxGroup,
  InputRadioGroup,
  InputQuantityGroup,
  PriceList,
} from '../../../../ui'
// import ArrowFat from '../../../../../assets/icons/icon_solid_arrow.svg'
import useStyles from './product-addon-section-style'

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]')
}

const SectionHeader = ({
  title,
  description,
  required,
  error,
}) => {
  const styles = useStyles({ error, required })
  if (_.isEmpty(title) && _.isEmpty(description)) return false
  return (
    <header
      className={styles.sectionListHeaderContainer}
    >
      {/* {error && <Sprites.IconCaution className={styles.sectionListHeaderIconCaution} />} */}
      <h3
        className={styles.sectionListHeaderText}
      >
        {title}
      </h3>
      {!_.isEmpty(description) && <p className={styles.sectionListHeaderDesc}>{description}</p>}
    </header>
  )
}

const ProductAddonSectionView = ({
  id: groupId,
  currency,
  description,
  disabled,
  error,
  maximumPerSkuQuantity,
  maximumSkuSelect,
  maximumTotalQuantity,
  meta,
  onChange,
  onUpdate,
  options: rawOptions,
  required,
  title,
  type,
}) => {
  const { i18n } = useTranslation()
  const styles = useStyles()
  // let field = null
  const options = _.map(rawOptions, (option) => {
    const {
      price,
    } = option
    return {
      ...option,
      content: price && (
        <PriceList
          items={option.price}
          className={styles.price}
          currency={currency}
        />
      ),
    }
  })
  // const selected = type === 'single'
  //                   ? _.get(_.find(options, (option) => _.get(option, 'quantity', 0) > 0), 'id')
  //                   : type === 'multiple'
  // eslint-disable-next-line max-len
  //                     ? _.compact(_.map(options, ({quantity, id: skuId}) => quantity > 0 && skuId))
  //                     : undefined
  const value = useMemo(() => {
    switch (type) {
      case 'single':
        return _.get(_.find(options, (option) => _.get(option, 'quantity', 0) > 0), 'id')
      case 'multiple':
        return _.compact(_.map(options, ({ quantity, id: skuId }) => quantity > 0 && skuId))
      case 'quantity':
        return _.reduce(options, (result, { id, quantity }) => {
          _.set(result, id, quantity)
          return result
        }, {})
      default:
        return undefined
    }
  }, [options, type])
  const headerProps = {
    title,
    description,
    error,
    required,
  }
  const groupTitle = useMemo(() => _.get(meta, `groupTitle.${_.camelCase(i18n.language)}`), [meta, i18n.language])
  const addonCustomerRemarks = useMemo(() => _.get(meta, 'addonCustomerRemarks', []), [meta])
  const viewProps = {
    groupId,
    title: groupTitle,
    style: styles.fieldGroup,
    options,
    onChange,
    onUpdate,
    value,
    disabled,
    addonCustomerRemarks,
  }
  const checkboxViewProps = {
    ...viewProps,
    max: maximumSkuSelect,
  }
  const quantityViewProps = {
    ..._.omit(viewProps, ['selected']),
    maxNoOfItemCount: maximumSkuSelect,
    maxNoOfTotalCount: maximumTotalQuantity,
    maxNoOfPerItemCount: maximumPerSkuQuantity,
    value,
  }
  return (
    <section>
      <SectionHeader {...headerProps} />
      <div className={[
        styles.sectionListItemContainer,
      ]}
      >
        { type === 'single'
        && <InputRadioGroup {...viewProps} /> }
        { type === 'multiple'
        && <CheckboxGroup {...checkboxViewProps} /> }
        { type === 'quantity'
        && <InputQuantityGroup {...quantityViewProps} /> }
      </div>
    </section>
  )
}

export default ProductAddonSectionView

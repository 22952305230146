import React from 'react'
import useStyles from './suggestions-style'
import {
  Title,
  RecommendationProducts,
} from '../../../../ui'

const SuggestionsView = ({ title, ...rest }) => {
  const styles = useStyles()
  return (
    <div className={styles.container}>
      <RecommendationProducts
        {...rest}
        ListHeaderComponent={(
          <Title
            margin="0 0 1.2rem"
            text={title}
          />
        )}
      />
    </div>
  )
}

export default SuggestionsView

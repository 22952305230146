import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  cardAdd: {
    marginTop: `${theme.gap}rem`,
    marginBottom: '2.2rem',
    padding: '1rem 2rem',
    background: theme.colors.bgLight,
    maxWidth: '42rem',
  },
  details: {
    textAlign: 'left',
    marginTop: '1rem',
  },
  line: {
    marginBottom: '0',
    display: 'flex',
    alignItems: 'baseline',
    width: '100%',
  },
  label: {
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: 1.7,
    textTransform: 'uppercase',
    width: '40%',
    paddingRight: '2rem',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      width: '32%',
    },
  },
  loadingDots: {
    fontSize: '0.5rem',
  },
  value: {
    fontSize: '1.2rem',
    lineHeight: 2,
    flex: 1,
    margin: 0,
    wordBreak: 'break-all',
  },
  price: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: theme.colors.alert,
  },
  buttonContainer: {
    margin: [['1rem', 0]],
  },
  addToCartButton: {
    minHeight: '5rem',
    paddingLeft: '4.2rem',
    paddingRight: '2rem',
    backgroundColor: theme.config.ui.buttons.addToCart.active.backgroundColor,
    border: theme.config.ui.buttons.addToCart.active.border,
  },
  addToCartButtonDisabled: {
    backgroundColor: theme.config.ui.buttons.addToCart.disabled.backgroundColor,
    border: theme.config.ui.buttons.addToCart.disabled.border,
  },
  addToCartButtonIcon: {
    margin: [[0, '0.4rem']],
  },
  backInStockNotificationButton: {
    backgroundColor: theme.colors.bgMedium,
    borderColor: theme.colors.bgMedium,
  },
  backInStockNotificationButtonIcon: {
    margin: [[0, '0.4rem']],
  },
  backInStockNotificationButtonDisabled: {
    opacity: 0.5,
  },
}))

export default useStyles
